import { writable } from 'svelte/store';

export const myfields = writable(0);
export const myVRAmaps = writable(0);
export const myroutes = writable(0);
export const fldtracks = writable(0);
export const myvehicles = writable(0);
export const sveltemap_global = writable(0);
export const tractorParamz = writable(0);
export const hasActiveVehicles = writable(false);
export const myisobuslogs = writable(0);