<script>
    //alert ("FLD");
    import { createEventDispatcher, onDestroy, setContext } from 'svelte';
    const dispatch = createEventDispatcher();
    import {tractor_position} from './login_store.js'
    import {myfields, myvehicles, tractorParamz, hasActiveVehicles} from './myfields_store.js'
    import {myroutes,fldtracks} from './myfields_store.js'
    import {main_map} from './map_store'
    import { onMount } from 'svelte';
    import Map from 'ol/Map'
    import View from 'ol/View'
    import TileLayer from 'ol/layer/Tile'
    import LineString from 'ol/geom/LineString'
    import {defaults} from 'ol/control';
    import {Feature, Size} from 'ol';
    import {Text,Fill, Stroke, Style as olStyle, Icon as olIcon } from 'ol/style';
    import Draw from 'ol/interaction/Draw'
    import * as proj from 'ol/proj';
    import {Circle, Point} from 'ol/geom'
    import {Circle as StyleCircle} from 'ol/style'
    import { OSM,BingMaps,XYZ} from 'ol/source';
    import {Vector as VectorSource} from 'ol/source';
    import {Vector as VectorLayer} from 'ol/layer';
 
    import Dialog, {Title, Content,Actions,InitialFocus} from '@smui/dialog';
    import TextField from '@smui/textfield';
    import Button, {Label} from '@smui/button'
    import { expressionToGlsl } from 'ol/style/expressions.js';

    import Modal, {closeModal,} from './components/Modal.svelte'
    import { _ } from './services/i18n';

  
    let showSaveFldModal = false;
    let saveFldModal2;
    let api_host = 'https://tractorpilot.com'
    if (location.host.includes("localhost"))  api_host = 'http://tp.localhost'

    let open = false;
    let field_coords = [];
    let new_fld_name = '';
    let polygon_coord = [];
    let map = null
    let prevCoords = [];
    const map_styles = [
  'RoadOnDemand',
  'Aerial',
  'AerialWithLabelsOnDemand',
  'CanvasDark',
  'OrdnanceSurvey',
];

    const raster = new TileLayer({source: new XYZ({
      url: 'http://mt0.google.com/vt/lyrs=s&hl=ru&x={x}&y={y}&z={z}'
    })});
    const source = new VectorSource({wrapX: false});
    const vector = new VectorLayer({source: source});
    let typeSelect = "Polygon"; //LineString Polygon
    var objectType = ""; 
    let tractorMarkers = {};
    let tractorParams = {};
    let routeHolder = {};
    let tracksHolder = {};

    //let tractorMarkerStyle = new olStyle();
    let draw

    function handleMessage(event) {
		alert(event.detail.text);
	}

    export function testFunc()
    {

        alert ('1234567890');
        
    }

 

    export function addPoint2Fld(map_obj) {
        let point;
        const borderLineStyle = new olStyle({
          stroke : new Stroke({color:'rgba(255,0,0,1)', width:4})
       });
        let pointStyle = new olStyle({
            image: new StyleCircle({
            radius: 5,
            stroke: new Stroke({
        color: 'lightblue', width: 2
        }),
            fill: new Fill({
                color: 'rgba(255,0,0,0.9)'
            }),
        }),
        });

         if (point=prompt($_('Add point LAT,LON (ex. 50.12345,60.12345)'))) {
             //Add point LAT,LON (ex. 50.12345,60.12345)
             let coords = point.split(",");
             var fldPoint = new Feature({
            geometry: new Point(proj.fromLonLat([ coords[1],coords[0]]))
        });

            fldPoint.setStyle(pointStyle);
            source.addFeature(fldPoint)
            polygon_coord.push ([coords[1],coords[0]]);
             centerMap (coords[1],coords[0],14);
           
             if (prevCoords.length>0) {
                 let p1 = proj.fromLonLat([coords[1],coords[0]]);
                 let p2 = proj.fromLonLat([prevCoords[1],prevCoords[0]]);
                 let line = new LineString([p1, p2]);
                 let fieldBorderLine = new Feature({geometry: line, name: "Line"});
                 fieldBorderLine.setStyle(borderLineStyle);
                 source.addFeature(fieldBorderLine);    
             }

             prevCoords = coords;

             setTimeout(function() {
                addPoint2Fld(map_obj)
             },1000 );    
            
         } else
         {
            if (polygon_coord[0]!=polygon_coord[polygon_coord.length-1]) {
                polygon_coord.push([polygon_coord[0][0],polygon_coord[0][1]]);
            } 
            saveFldModal();
         }    
    }

     export function addInteraction(map_obj,draw_type) 
    {
        typeSelect = draw_type
        objectType = typeSelect.value;
        if (objectType !== 'None') {
            draw = new Draw({
            source: source,
            type: typeSelect,
            });
            map_obj.addInteraction(draw);
        }

        draw.on('drawend', function(evt){
            polygon_coord = [];
            var feature = evt.feature;
            var coords = feature.getGeometry().getCoordinates();
            if (feature.getGeometry().getType()=='Polygon') coords = coords[0];
            
            coords.forEach(element => {
                polygon_coord.push (proj.toLonLat(element));
            });

            console.log(JSON.stringify(polygon_coord));
            saveFldModal();

        } );

    }

    export function initTractorMarker(vehicleId,vehicleName)
    {
        let tractorMarker = new Feature();

        let tractorMarkerStyle = new olStyle({
          image: new olIcon({
            src: api_host+'/assets/images/tractor.png',
            scale:0.1
          }),

        text: new Text({
        text:vehicleName,        
        font: '12px Calibri,sans-serif',
        //fill: new Fill({ color: '#000' }),
        stroke: new Stroke({
        color: 'lightblue', width: 2
        }),
        overflow: true,
        offsetY: -18,
        textAlign: 'center',
        textBaseline: 'middle',
        //backgroundFill: new Fill({ color: 'rgba(255, 255, 255, 0.6)' }),
        padding: [5, 5, 5, 5],
        placement: 'point'
    }) 

        });

 

        tractorMarker.setStyle(tractorMarkerStyle);
        

        source.addFeature(tractorMarker);
        tractorMarkers[vehicleId] = tractorMarker;
    }



    function moveTractorMarker(lon_lat_arr, heading, vehicleId)
    {
        tractorMarkers[vehicleId].setGeometry(new Point(proj.fromLonLat(lon_lat_arr)));   
        tractorMarkers[vehicleId].getStyle().getImage().setRotation(heading)
    }

    export function centerMap(lon,lat,zum=14)
    {
      if (lon>0 || lat>0)
      map.setView(new View({
               center: proj.fromLonLat([lon, lat]),
               zoom : zum,
            })) 
    }

    export async function showFieldOnMap(field_id)
    {
       field_coords = await fieldCoords(field_id);
       const borderLineStyle = new olStyle({
          stroke : new Stroke({color:'rgba(255,0,0,1)', width:4})
       });
       
       //moveTractorMarker(field_coords[0]);
       for (let i = 0; i < field_coords.length-1; i++) {

        let p1 = proj.fromLonLat([field_coords[i][0],field_coords[i][1]]);
        let p2 = proj.fromLonLat([field_coords[i+1][0],field_coords[i+1][1]]);
        let line = new LineString([p1, p2]);
        let fieldBorderLine = new Feature({geometry: line, name: "Line"});
        fieldBorderLine.setStyle(borderLineStyle);
        source.addFeature(fieldBorderLine);
         
        }

         map.setView(new View({
               center: proj.fromLonLat([field_coords[0][0], field_coords[0][1]]),
               zoom : 16
            })) 
       
    }
    
    let viewDiv = "openlayersmap";

    const setupMap = (node, _id) => 
    {
        map = new Map({
                layers: [raster, vector],
                target: 'openlayersmap',
                view: new View({
                center: proj.fromLonLat([50.221245, 53.241505]),
                zoom: 12
            }
                
                ),

                
                controls : defaults({
        rotate:false,
        attribution : false,
        zoom : false,

    }),


        });
    
        main_map.set(map)
        getMyVehicles();
        tractor_position.subscribe((value)=>{
          //centerMap(value.lon,value.lat)
          if (value.vehicle_id>0) {
            if (tractorParams[value.vehicle_id]) {  
            tractorParams[value.vehicle_id].speed = value.speed.toFixed(1);  
            tractorParams[value.vehicle_id].jobProgress = value.jobProgress.toFixed(1);
            tractorParams[value.vehicle_id].fieldName = value.fieldName;
            tractorParams[value.vehicle_id].lat = value.lat;
            tractorParams[value.vehicle_id].lon = value.lon;
            tractorParamz.set(tractorParams);
            moveTractorMarker([value.lon,value.lat],value.heading,value.vehicle_id)
          }
        }
        })

        return {
            destroy() {
                if (map) {
                    map.setTarget(null);
                    map = null;
                }
            }
        }
    }

    async function getMyVehicles() {
       let myVehiclesReq = await fetch(api_host+"/Vehicles/getUserVehicles/"+localStorage.getItem('client_code'));
       let myVehiclez = await myVehiclesReq.json();
       for (let i=0; i<myVehiclez.length;i++) {
           initTractorMarker(myVehiclez[i]['vId'], myVehiclez[i]['vName']);
           tractorParams[myVehiclez[i]['vId']] = {"speed":0,"jobProgress":0,"fieldName":"","lat":0,"lon":0}
           tractorParamz.set(tractorParams);
           //console.log(myVehiclez[i]['vId']);
       }
       myvehicles.set(myVehiclez)
    }

    export async function getMyFields()
    {
       let my_fields_response = await fetch(api_host+"/Fields/userFieldsList/"+localStorage.getItem('client_code'));
       let my_fields_data = await my_fields_response.json();
       myfields.set(my_fields_data);
    }

    async function getMyRoutes()
    {
        //let my_routes_response = await fetch(api_host+"/Fields/fieldRoutes/"+localStorage.getItem('client_code'));
        //let my_routes_data = await my_routes_response.json();
        //myroutes.set(my_routes_data);
    }

    export function fldNameClicked(fieldId) {
        showFieldOnMap(fieldId);
        getFieldRoutes(fieldId);
        getFieldTracks(fieldId);
    }

    export function hideRoute(routeId) {
        changeRouteColor(routeId,'transparent',0);
    }

    export function hideTrack(routeId) {
        changeTrackColor(routeId,'transparent',0);
    }

    export function changeRouteColor(routeId, newColor,width) {
        const newStyle = new olStyle({
            stroke : new Stroke({color:newColor, width:1})
        });

        if (routeHolder[routeId])
        for (let i=0; i<routeHolder[routeId].length; i++) {
            routeHolder[routeId][i]["feature"].setStyle(newStyle);
        }
    }

    export function changeTrackColor(trackId, newColor) {
        const newStyle = new olStyle({
            stroke : new Stroke({color:newColor, width:1})
        });

        if (tracksHolder[trackId]) {
            tracksHolder[trackId]["feature"].setStyle(newStyle);
        }
    }

    async function getFieldRoutes(fieldId)
    {
        let my_routes_response = await fetch(api_host+"/Fields/fieldRoutes/"+localStorage.getItem('client_code')+"/"+fieldId);
        let my_routes_data = await my_routes_response.json();
        myroutes.set(my_routes_data);
    }

    async function getFieldTracks(fieldId) {
        let fld_tracks_req = await fetch(api_host+"/Fields/fieldTracks/"+localStorage.getItem('client_code')+"/"+fieldId);
        let fld_tracks_data = await fld_tracks_req.json();
        fldtracks.set(fld_tracks_data);
        //fld_tracks
    }

   export async function loadTrack(trackId,trackName, routeColor) {
       
        if (!tracksHolder[trackId]) {
            const trackLineStyle = new olStyle({
            stroke : new Stroke({color:routeColor, width:1})
        });

       let trackCoordsReq = await fetch(api_host+"/Fields/trackCoords/?trackFile="+trackName);
       let trackCoordsData = await trackCoordsReq.json();
       let coords = trackCoordsData.track_coords;
       //alert (coords.length);
            let coordsArr = [];   
            coords.forEach(coord=> {
                if (coords!="") {
                let lat_lon = coord.split(',');
                let path_point = proj.fromLonLat([lat_lon[0], lat_lon[1]]);
                coordsArr.push(path_point);
                }
                
            }) 

            const line = new LineString(coordsArr);
            const trackLineFeature = new Feature({geometry: line});
            trackLineFeature.setStyle(trackLineStyle)
            source.addFeature(trackLineFeature);
            let tmpFeature = {"feature":trackLineFeature, "style":trackLineStyle};
            if (!tracksHolder[trackId]) tracksHolder[trackId] = new Object();
            tracksHolder[trackId]=tmpFeature;
            //alert (tracksHolder[trackId]);
        }
        else {
            tracksHolder[trackId]["feature"].setStyle(tracksHolder[trackId]["style"]);
        }
        //alert (trackName);
        //alert (trackCoordsData);
    }


    export async function getFieldRoute(routeId,clientCode,routeColor)
    {     
        if (!routeHolder[routeId]) {
        const borderLineStyle = new olStyle({
            stroke : new Stroke({color:routeColor, width:1})
        });
        let rqst = await fetch(api_host+"/Fields/getFieldRoute/"+routeId+"/"+clientCode);
        let my_fields_data = await rqst.json();
        let i=0;
        my_fields_data.forEach(fld_path => {
        
            let coords = JSON.parse(fld_path.path_coords);
            let coordsArr = [];   
            coords.forEach(coord=> {
                let lat_lon = coord.split(',');
                
                let path_point = proj.fromLonLat([lat_lon[1], lat_lon[0]]);
                coordsArr.push(path_point);
                //let path_point_circle = new Circle(path_point,0.01)
                //let path_point_feature = new Feature({geometry: path_point_circle, name: "PNT"});
                //path_point_feature.setStyle(borderLineStyle);
                //source.addFeature(path_point_feature);
            })

            const line = new LineString(coordsArr);
            const routeFeature = new Feature({geometry: line});
            routeFeature.setStyle(borderLineStyle)
            source.addFeature(routeFeature);
           
            let tmpFeature = {"feature":routeFeature, "style":borderLineStyle};
            if (!routeHolder[routeId]) routeHolder[routeId]=[];

            routeHolder[routeId].push (tmpFeature);

            i++;
            //routeHolder.push(routeFeature);
            //source.removeFeature(routeFeature);

        }); 
    }
    else {
        for (let i=0; i<routeHolder[routeId].length; i++) {
            routeHolder[routeId][i]["feature"].setStyle(routeHolder[routeId][i]["style"]);
        }
        //alert (routeHolder[routeId][0]);
        //routeHolder[routeId]["feature"].setStyle(routeHolder[routeId]["style"]);
        //alert ("Route Loaded!");
    }
    }

    export async function fieldCoords(field_id)
    {
      let field_coords_response = await fetch(api_host+"/Fields/get_coords/"+field_id);
      field_coords = await field_coords_response.json();
      return field_coords;
       //myfields.set(my_fields_data);
    }

    async function addField()
    {
        let add_map_response = await fetch(api_host+"/Fields/saveField/"+localStorage.getItem('client_code'), 
        {
            body: "object_type="+typeSelect+"&field_name="+new_fld_name+"&field_coords="+JSON.stringify(polygon_coord),
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            method: "post"
        }
        );

        let add_map_response_data = await add_map_response.json();
        
        saveFldModal2.closeModal();
        document.location="/#fields?x=1"
        getMyFields()
    }

    export async function delField(fld_id)
    {
        if (confirm('Удалить это поле?'))
        {
          let del_fld_response = await fetch(api_host+"/Fields/deleteFld/"+fld_id+"/"+localStorage.getItem('client_code'));
          let del_resp = await del_fld_response.json();
          if (del_resp['status']==1)   getMyFields();
        }
    }

    export async function delRoute(fld_id,routeName)
    {
        if (confirm('Удалить этот маршрут?'))
        {
          let del_fld_response = await fetch(api_host+"/Fields/deleteRoute/"+fld_id+"/"+routeName+"/"+localStorage.getItem('client_code'));
          let del_resp = await del_fld_response.json();
          if (del_resp['status']==1)   getMyRoutes();
        }
    }

    export function saveFldModal()
    {
        showSaveFldModal = true;
    }

  
    onMount(() => {
    getMyFields();
    //getMyRoutes();
	});
</script>

<div style='height:800px; width:100%' id={viewDiv} class="map relative w-full rounded-xl shadow-lg" use:setupMap={viewDiv} on:message={handleMessage} >
</div>


{#if showSaveFldModal}
<Modal on:close="{() => showSaveFldModal = false}" bind:this={saveFldModal2}>
    <div class="body-nest" id="save_fld_modal">
      <div class="form_center">
    
          <form action="/#fields" id="contact-for" class="form-horizontal" novalidate="novalidate">
              <fieldset>
    
    
                  <div class="control-group">
                      <label class="control-label" for="name">{$_("field_name")}</label>
                      <div class="controls">
                          <input type="text" class="form-control" bind:value={new_fld_name}>
                      </div>
                  </div>
             
                 
                  <div class="form-actions" style="margin:20px 0 0 0;">
                      <button type="button" class="btn btn-primary" on:click={addField}>{$_("save")}</button>
                      <button type="reset" class="btn" on:click={saveFldModal2.closeModal()}>{$_("cancel")}</button>
                  </div>
              </fieldset>
          </form>
    
      </div>
    </div>
    </Modal>
    {/if}

