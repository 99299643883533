<script>
    import { createEventDispatcher } from 'svelte'
    import {main_map} from '../map_store'
    import {myfields, user_loged_in,sveltemap_global, myroutes, myvehicles,tractorParamz, hasActiveVehicles, fldtracks} from '../myfields_store.js'
    import { get,set,update } from 'svelte/store';
    import {tractor_position} from '../login_store.js'
	import OpenlayersMap, {addInteraction,testFunc,showFieldOnMap, saveFldModal,} from '../Map.svelte'

    import Dialog, {Title, Content} from '@smui/dialog';
    import List, { Item,Graphic,Meta,Text,PrimaryText,SecondaryText,Separator} from '@smui/list';
    import { fix_and_destroy_block } from 'svelte/internal';
    import Map from '../Map.svelte';

    import { _ } from '../services/i18n';
    
    let open = false;
    let mapsvelte;
    let routeChkboxState = {};
    let api_host = 'https://tractorpilot.com'
    if (location.host.includes("localhost"))  api_host = 'http://tp.localhost'

    export function vehicleFocus(vLat,vLon) {
        $sveltemap_global.centerMap(vLon,vLat,14);
        //alert (vLat);
        //alert(vLon);
    }

    function changeRouteColor(routeId) {
        if (document.getElementById(routeId+'_chk').checked) {
        $sveltemap_global.changeRouteColor(routeId,document.getElementById(routeId+'_color').value,1);
        }
    }

    function changeTrackColor(trackId) {
        if (document.getElementById(trackId+'_chkt').checked) {
            $sveltemap_global.changeTrackColor(trackId,document.getElementById('tcolor_'+trackId).value,1);
        }
    }

 
    function routeChkboxClicked(routeId,clientCode) {

        if (document.getElementById(routeId+'_chk').checked) { 
            $sveltemap_global.getFieldRoute(routeId,clientCode,document.getElementById(routeId+'_color').value)
        }
        else {
            $sveltemap_global.hideRoute(routeId);
        }
    }

    function trackChkboxClicked(trackId, trackPath) {
        if (document.getElementById(trackId+'_chkt').checked) { 
             $sveltemap_global.loadTrack(trackId, trackPath, document.getElementById('tcolor_'+trackId).value)
        }
        else {
            $sveltemap_global.hideTrack(trackId);
        }
    }
    

 
    //alert (get(tractor_position).lat);
</script>

<div class="container-fluid paper-wrap bevel tlbr">

    <!-- CONTENT -->
    <!--TITLE -->
    <div class="row">
        <div id="paper-top">
            <div class="col-sm-3">
                <h2 class="tittle-content-header">
                    <i class="icon-location"></i> 
                    <span>{$_('field_maps')}
                    </span>
                </h2>

            </div>

       
            <div class="col-sm-7">

                {#if $fldtracks && $fldtracks.length > 0}
                <div class="btn-group btn-wigdet pull-right visible-lg">
                    <div class="btn">{$_('available_tracks')}</div>
                    <button data-toggle="dropdown" class="btn dropdown-toggle" type="button">
                        <span class="caret"></span>
                        <span class="sr-only">{$_('available_tracks')}</span>
                    </button>
                    <ul role="menu" class="dropdown-menu" style="min-width:200px" onclick="event.stopPropagation()">
                    {#each $fldtracks as track, i}
                    <li>
                        <input id={track.id}_chkt type="checkbox" style="margin-left:5px" on:change={() => trackChkboxClicked(track.id,track.path) }>
                    
                         <select id=tcolor_{track.id} on:change={()=>changeTrackColor(track.id)}>
                             <option value="rgba(145,238,145,1)">green</option>
                             <option value="red">red</option>
                             <option value="blue">blue</option>
                             <option value="orange">orange</option>
                             <option value="yellow">yellow</option>
                             <option value="white">white</option>
                         </select>   
                       <a>{track.name}</a>     

                    </li>
                    {/each}
                </ul>
                    </div>
                    {/if}

                <div class="devider-vertical visible-lg"></div>
               
           
                {#if $myroutes && $myroutes.length > 0}
                    <div class="btn-group btn-wigdet pull-right visible-lg">
                        <div class="btn">{$_('available_routes')}</div>
                        <button data-toggle="dropdown" class="btn dropdown-toggle" type="button">
                            <span class="caret"></span>
                            <span class="sr-only">{$_('available_routes')}</span>
                        </button>
                        <ul role="menu" class="dropdown-menu" style="min-width:200px" onclick="event.stopPropagation()">
                            
                            {#each $myroutes as route, i}
                            
                            <li>
                                <input type="checkbox" style="margin-left:5px" id={route.id}_chk
                                on:change={() => routeChkboxClicked(route.id,localStorage.getItem('client_code'))}
                                >
                            
                                 <select on:change={changeRouteColor(route.id)} id={route.id}_color>
                                     <option value="rgba(145,238,145,1)">green</option>
                                     <option value="red">red</option>
                                     <option value="blue">blue</option>
                                     <option value="orange">orange</option>
                                     <option value="yellow">yellow</option>
                                     <option value="white">white</option>
                                 </select>   

                                 <a> {route.routeName}</a>
                            </li>
                            
                            {/each}
                           
                          </ul>  
                   </div>  {/if}


            

            </div>
            <div class="col-sm-2">
                <div class="devider-vertical visible-lg"></div>
                <div class="btn-group btn-wigdet pull-right visible-lg">
                    
                    

                    <div class="btn">
                        {$_('actions')}</div>
                    <button data-toggle="dropdown" class="btn dropdown-toggle" type="button">
                        <span class="caret"></span>
                        <span class="sr-only">{$_('actions')}</span>
                    </button>
                    <ul role="menu" class="dropdown-menu">
                      
                        <li>
                            <a href="#" on:click="{$sveltemap_global.addInteraction($main_map,"Polygon")}">
                                <span class="entypo-plus-circled margin-iconic"></span>{$_('add_new_field')}</a>
                        </li>

                        <li>
                            <a href="#" on:click="{$sveltemap_global.addPoint2Fld($main_map)}">
                                <span class="entypo-plus-circled margin-iconic"></span>{$_('Add New Field By Control Points')}</a>
                        </li>

                        <!-- <li>
                            <a href="#" on:click="{$sveltemap_global.addInteraction($main_map,"LineString")}">
                                <span class="entypo-heart margin-iconic"></span>Добавить траекторию</a>
                        </li>
                        <li>
                            <a href="#" on:click="{$sveltemap_global.getFldPathLines(0)}" >
                                <span class="entypo-cog margin-iconic"></span>Setting</a>
                        </li> -->
                    </ul>
                </div>


            </div>
        </div>
    </div>
    <!--/ TITLE -->



    <div class="content-wrap">
        <div class="row">


            <div class="col-sm-12">
                <div class="nest" id="GmapClose">
                  

                    <div style="padding:0;" class="body-nest" id="Gmap">

                        <div id="test1" class="gmap" style="width:100%;height:500px;position:relative;"><OpenlayersMap bind:this={$sveltemap_global}/></div>

                                           </div>
                </div>
            </div>

        </div>
    </div>


    <div class="content-wrap">
        <div class="row">


        </div>
    </div>







    {#if $hasActiveVehicles==true}
    <div class="content-wrap" style="margin-top:140px;">
        <div class="row">
            <div class="col-sm-6">
                <div id="siteClose" class="nest">
             
                    <div id="site" class="body-nest" >
                           <h5 style="margin-top: 0px; margin-bottom:0px">
                               <span class="fontawesome-truck"></span>OnLine Vehicles</h5>
                        <div class="table-responsive">
                         
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                   
                                        {#each $myvehicles as veh, i}
                                        {#if $tractorParamz[veh.vId].lon!=0 && $tractorParamz[veh.vId].lat!=0}
                                        <tr>
                                        <td class="armada-devider">
                                            <div class="armada">
                                                <span style="background:#65C3DF">
                                                    <span class="maki-bus"></span>{$tractorParamz[veh.vId].fieldName}</span>
                                                <p>
                                                    <span class="entypo-gauge"></span>
                                                    &nbsp; {$tractorParamz[veh.vId].speed} Km/<i>Hours</i>
                                                </p>
                                            </div>
                                        </td>
                                        <td class="driver-devider">
                                            <!-- svelte-ignore a11y-missing-attribute -->
                                            <img class="armada-pic img-circle" src="https://tractorpilot.com/images/logo_mini.png">
                                            <h3>{veh.vName}</h3>
                                            <br>
                                            <p>ID: {veh.vId} </p>
                                            <br>
                                            <p><a style="cursor: pointer;" on:click={vehicleFocus($tractorParamz[veh.vId].lat,
                                            $tractorParamz[veh.vId].lon)}>
                                                Show on the map</a></p>
                                        </td>
                                        <td class="progress-devider">


                                            <section id="basic">
                                                <article>

                                                    <div class="number-pb">
                                                        <div class="number-pb-shown" style="width:{$tractorParamz[veh.vId].jobProgress}%;"></div>
                                                        <div class="number-pb-num" style="left:{$tractorParamz[veh.vId].jobProgress}%">{$tractorParamz[veh.vId].jobProgress}%</div>
                                                    </div>
                                                </article>
                                            </section>
                                            <span class="label pull-left">Выполнение задания</span>
                                        

                                        </td>
                                    </tr>
                                    {/if}
                                    {/each}
                                   
                             
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4">
      
            </div>
        </div>
    </div>
    {/if}


    <div class="footer-space"></div>
    <div id="footer">
        <div class="devider-footer-left"></div>
        <div class="time">
            <p id="spanDate"></p>
            <p id="clock"></p>
        </div>
        <div class="copyright">
            <span class="entypo-heart"></span>2023 <a href="#">Tractor Pilot</a> All Rights Reserved</div>
        <div class="devider-footer"></div>

    </div>



</div>


